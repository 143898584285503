import React from 'react'
import GatsbyImage from 'gatsby-image'
import styled from '@emotion/styled'
import ThemeConsumer from '@/hoc/ThemeConsumer'
import { desktop, laptop, tablet, mobile } from '@/utils/media'
import { mq } from '@/utils/helpers'
import { layouts } from '@/utils/preset'
import Typography from '@/components/common/Typography'

const FeatureItem = props => {
  const { images, body, ...others } = props
  return (
    <Root {...others}>
      <Body variant="body2">{body}</Body>
      <ImageList>
        <ImageList_Container>
          {images.map((item, index) => (
            <ImageList_Item key={index}>
              <Item>
                <Item_Image fluid={item.image.childImageSharp.fluid}></Item_Image>
                <Item_Caption variant="body2">{item.caption}</Item_Caption>
              </Item>
            </ImageList_Item>
          ))}
        </ImageList_Container>
      </ImageList>
    </Root>
  )
}

const Root = styled.div`
`

const Body = styled(Typography)`
  display: none;
  @media ${mq.and(mobile)} {
    display: block;
    margin: 0 auto 24px;
    max-width: ${layouts.defs.contentMaxWidth.small}px;
  }
`

const ImageList = styled.div``

const ImageList_Container = styled.ul`
  display: flex;
  @media ${mq.and(desktop, laptop, tablet)} {
    flex-wrap: wrap;
    margin: -24px -16px 0;
  }
  @media ${mq.and(mobile)} {
    flex-direction: column;
    margin-top: -24px;
  }
`

const ImageList_Item = styled.li`
  @media ${mq.and(desktop, laptop, tablet)} {
    width: 50%;
    margin-top: 24px;
    padding: 0 16px;
  }
  @media ${mq.and(mobile)} {
    margin-top: 24px;
  }
`

const Item = styled.div``

const Item_Image = styled(GatsbyImage)``

const Item_Caption = styled(Typography)`
  margin-top: 8px;
  text-align: center;
`

export default ThemeConsumer(FeatureItem)
