import React from 'react'
import MuiExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary'
import ThemeConsumer from '@/hoc/ThemeConsumer'
import WithStylesProps from '@/hoc/WithStylesProps'
import MuiWrapper from '@/hoc/MuiWrapper'
import { desktop, laptop, tabletL, mobile } from '@/utils/media'
import { mq } from '@/utils/helpers'
import { getThemeColor } from '@/utils/themes'

const ExpansionPanelSummary = props => {
  const { theme, classes, children, className, ...other } = props
  return (
    <MuiExpansionPanelSummary
      className={className}
      classes={{
        ...get(classes)
      }}
      theme={theme}
      {...other}
    >
      { children }
    </MuiExpansionPanelSummary>
  )
}

const getColors = (props) => {
  const color = props.color || 'text'
  return {
    text: getThemeColor(color, props.theme),
    background: getThemeColor(color, props.theme, 'contrast'),
    border: getThemeColor('line', props.theme)
  }
}

export const make = props => {
  const colors = getColors(props)
  const media = {
    mobile: `@media ${mq.and(mobile)}`,
    large: `@media ${mq.and(desktop, laptop, tabletL)}`
  }
  return {
    ExpansionPanelSummary_root: {
      padding: '20px 32px',
      boxShadow: 'none',
      [media.mobile]: {
        padding: '24px 16px'
      },
      backgroundColor: colors.background,
      [media.large]: {
        position: 'sticky',
        top: 0,
        zIndex: 1
      },
      border: `1px solid ${colors.border}`
    },
    ExpansionPanelSummary_content: {
      margin: '12px 0',
      color: colors.text,
      [media.mobile]: {
        margin: '0'
      }
    },
    ExpansionPanelSummary_expandIcon: {
      color: colors.text,
      margin: '0 !important'
    },
    ExpansionPanelSummary_expanded: {
      [media.mobile]: {
        margin: '0 !important'
      }
    }
  }
}

export const get = classes => {
  const filteredClasses = {}
  Object.keys(classes).forEach(className => {
    if (className.indexOf('ExpansionPanelSummary_') === 0) {
      filteredClasses[className.replace('ExpansionPanelSummary_', '')] = classes[className]
    }
  })
  return filteredClasses
}

export default MuiWrapper(ThemeConsumer(
  WithStylesProps(make)(ExpansionPanelSummary)
), 'ExpansionPanelSummary')
