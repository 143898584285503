import React from 'react'
import GatsbyImage from 'gatsby-image'
import styled from '@emotion/styled'
import { css } from '@emotion/core'
import ThemeConsumer from '@/hoc/ThemeConsumer'
import { mobile } from '@/utils/media'
import { mq } from '@/utils/helpers'
import Item from './Item'
import ExpansionPanel from '@/components/common/ExpansionPanel'
import ExpansionPanelSummary from '@/components/common/ExpansionPanelSummary'
import ExpansionPanelDetails from '@/components/common/ExpansionPanelDetails'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import Typography from '@/components/common/Typography'

const FeatureList = props => {
  const { list, ...others } = props
  return (
    <Root {...others}>
      <List>
        <List_Container>
          {list.map((item, index) => (
            <List_Item key={index}>
              <ExpansionPanel>
                <ExpansionPanelSummary
                  color="gray.e"
                  expandIcon={<ExpandMoreIcon />}>
                  <ItemHead>
                    {item.icon && (
                      <ItemHead_Image>
                        <GatsbyImage fluid={item.icon.childImageSharp.fluid} />
                      </ItemHead_Image>
                    )}
                    <ItemHead_Main>
                      <ItemHead_Title variant="h4" component="h2">{item.title}</ItemHead_Title>
                      <ItemHead_Body variant="body2" color="gray.d">{item.body}</ItemHead_Body>
                    </ItemHead_Main>
                  </ItemHead>
                </ExpansionPanelSummary>
                <ExpansionPanelDetails>
                  <Item css={styles.Item} {...item} />
                </ExpansionPanelDetails>
              </ExpansionPanel>
            </List_Item>
          ))}
        </List_Container>
      </List>
    </Root>
  )
}

const Root = styled.div`
`

const List = styled.div`
  margin-top: -10px;
`

const List_Container = styled.ul`
  margin-top: -10px;
`

const List_Item = styled.li`
  margin-top: 10px;
`

const ItemHead = styled.div`
  display: flex;
  align-items: center;
`

const ItemHead_Image = styled.div`
  width: 80px;
  height: 80px;
  flex-shrink: 0;
  flex-grow: 0;
  margin-right: 32px;
  @media ${mq.and(mobile)} {
    margin-right: 16px;
    width: 40px;
    height: 40px;
  }
`

const ItemHead_Main = styled.div`
`

const ItemHead_Title = styled(Typography)`
`

const ItemHead_Body = styled(Typography)`
  margin-top: 12px;
  @media ${mq.and(mobile)} {
    display: none;
  }
`

const styles = {
  Item: css`
    width: 100%;
  `
}

export default ThemeConsumer(FeatureList)
