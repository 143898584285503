import React from 'react'
import styled from '@emotion/styled'
import ThemeConsumer from '@/hoc/ThemeConsumer'
import { layouts } from '@/utils/preset'
import { mobile } from '@/utils/media'
import { mq } from '@/utils/helpers'
import Bg from '@/components/app/Bg'
import Features from '@/components/svg/Features'

const AboutOverview = props => {
  return (
    <Root {...props}>
      <Main>
        <Chart>
          <Chart_Content />
        </Chart>
      </Main>
    </Root>
  )
}

const Root = styled.div`
  padding: 100px 0 120px;
  @media ${mq.and(mobile)} {
    padding: 48px 0 64px;
  }
`

const Main = styled.div``

const Chart = styled(Bg)`
  margin: 0 auto;
  max-width: 996px;
  padding: 0 38px;
  @media ${mq.and(mobile)} {
    margin-left: ${layouts.defs.pad.mobile * -1}px;
    margin-right: ${layouts.defs.pad.mobile * -1}px;
    padding-left: ${layouts.defs.pad.mobile}px;
    padding-right: ${layouts.defs.pad.mobile}px;
  }
`

const Chart_Content = styled(Features)`
  width: 100%;
  max-width: 772px;
  margin: 0 auto;
`

export default ThemeConsumer(AboutOverview)
