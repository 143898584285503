import React from 'react'
import MuiExpansionPanel from '@material-ui/core/ExpansionPanel'
import ThemeConsumer from '@/hoc/ThemeConsumer'
import WithStylesProps from '@/hoc/WithStylesProps'
import MuiWrapper from '@/hoc/MuiWrapper'

const ExpansionPanel = props => {
  const { theme, classes, children, className, ...other } = props
  return (
    <MuiExpansionPanel
      className={className}
      classes={{
        ...get(classes)
      }}
      {...other}
    >
      { children }
    </MuiExpansionPanel>
  )
}

export const make = props => {
  return {
    ExpansionPanel_root: {
      backgroundColor: 'transparent',
      borderRadius: '0 !important',
      boxShadow: 'none',
      '&::before': {
        display: 'none'
      }
    }
  }
}

export const get = classes => {
  const filteredClasses = {}
  Object.keys(classes).forEach(className => {
    if (className.indexOf('ExpansionPanel_') === 0) {
      filteredClasses[className.replace('ExpansionPanel_', '')] = classes[className]
    }
  })
  return filteredClasses
}

export default MuiWrapper(ThemeConsumer(
  WithStylesProps(make)(ExpansionPanel)
), 'ExpansionPanel')
