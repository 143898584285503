import React from 'react'
import MuiExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails'
import ThemeConsumer from '@/hoc/ThemeConsumer'
import WithStylesProps from '@/hoc/WithStylesProps'
import { desktop, laptop, tabletL, mobile } from '@/utils/media'
import { mq } from '@/utils/helpers'
import MuiWrapper from '@/hoc/MuiWrapper'

const ExpansionPanelDetails = props => {
  const { theme, classes, children, className, ...other } = props
  return (
    <MuiExpansionPanelDetails
      className={className}
      classes={{
        ...get(classes)
      }}
      {...other}
    >
      { children }
    </MuiExpansionPanelDetails>
  )
}

export const make = props => {
  const media = {
    mobile: `@media ${mq.and(mobile)}`,
    large: `@media ${mq.and(desktop, laptop, tabletL)}`
  }
  return {
    ExpansionPanelDetails_root: {
      padding: '41px',
      [media.mobile]: {
        padding: '24px 16px'
      },
      backgroundColor: props.theme.line.base
    }
  }
}

export const get = classes => {
  const filteredClasses = {}
  Object.keys(classes).forEach(className => {
    if (className.indexOf('ExpansionPanelDetails_') === 0) {
      filteredClasses[className.replace('ExpansionPanelDetails_', '')] = classes[className]
    }
  })
  return filteredClasses
}

export default MuiWrapper(ThemeConsumer(
  WithStylesProps(make)(ExpansionPanelDetails)
), 'ExpansionPanelDetails')
