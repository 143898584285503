import React from 'react'
import { StaticQuery, graphql } from 'gatsby'
import styled from '@emotion/styled'
import ThemeConsumer from '@/hoc/ThemeConsumer'
import { mobile, mobileS } from '@/utils/media'
import { mq } from '@/utils/helpers'
import Headline from '@/components/app/Headline'
import List from './List'

const FeatureMainList = props => {
  const { ...others } = props
  return (
    <StaticQuery
      query={query}
      render={data => {
        const list = data.list.edges[0].node.main
        return (
          <Root {...others}>
            <Head>
              <Title theme={props.theme}>
                基本機能
              </Title>
            </Head>
            <Main>
              <List list={list} />
            </Main>
          </Root>
        )
      }}
    />
  )
}

const Root = styled.div`
  padding: 100px 0 120px;
  @media ${mq.and(mobile)} {
    padding: 48px 0 64px;
  }
`

const Head = styled.div``

const Title = styled(Headline)`
  strong {
    color: ${props => props.theme.primary.base};
  }
  @media ${mq.and(mobileS)} {
    br:nth-of-type(2) {
      display: none;
    }
  }
`

const Main = styled.div`
  margin-top: 90px;
  @media ${mq.and(mobile)} {
    margin-top: 48px;
  }
`

export default ThemeConsumer(FeatureMainList)

export const query = graphql`
  query FeatureMainListQuery {
    list: allFeatureListYaml {
      edges {
        node {
          main {
            title
            icon {
              childImageSharp {
                fluid(maxWidth: 160) {
                  ...GatsbyImageSharpFluid_noBase64
                }
              }
            }
            images {
              image {
                childImageSharp {
                  fluid(maxWidth: 735) {
                    ...GatsbyImageSharpFluid_noBase64
                  }
                }
              }
              caption
            }
            body
          }
        }
      }
    }
  }
`
